import { Component, ElementRef, inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { TopPageService } from './toppage.service';
import { environment } from 'projects/mamezo/src/environments/environment';
import { MamezoMessage, MailConfigInputForm, KeiyakuHojinListType, KyotenListT, SystemListType, SearchResultType, MailConfigRequestForm, SearchForm, SearchOptionType, MailGroupChoice, MailGroupChoiceList, SystemRoleListType,  } from '../toppage-types';
import { SearchFormComponent } from '../search-form.component/search-form.component';

const logger = environment.logger;

// モーダルを出すときに背景のスクロールをさせないようにする
var fixScroll = () => {
  Object.assign(document.body.style, {
    overflow: 'hidden',
    position: 'sticky',
    width: '100%',
  });
};
var releaseScroll = () => {
  Object.assign(document.body.style, {
    overflow: '',
    position: '',
    width: '',
  });
};
var scrollToTop = () => {
  window.scroll({top: 0});
}
var searchResultDivScrollTop = () => {
  document.getElementById('search_result_div')?.scrollTo(0, 0);
};
var scrollResultToTop = () => {
  // 検索結果が上部に来るとき、0, 0 座標に当たるのは検索ボタンのあたり
  setTimeout(() => document.getElementById('search_form_buttons')?.scrollIntoView({behavior: 'smooth'}), 350);
}

const defaultDisplayColConfig : Record<string, boolean> = {
  jusho: true,
  tantosha: true,
  role: true,
  lastUpdated: true,
  system1: true,
  system2: true,
  system3: true,
};

@Component({
  selector: 'app-toppage',
  templateUrl: './toppage.component.html',
  styleUrls: ['./toppage.component.scss'],
})
export class TopPageComponent {

  router: Router = inject(Router);
  topPageService: TopPageService = inject(TopPageService);

  /** 検索フォーム */
  searchForm: FormGroup;
  /** 検索条件：メール受信区分 */
  mailGroupChoiceList: MailGroupChoice[] = []; // search-formに渡すためだけの値
  /** あなたが参照可能な拠点 */
  kyotenCount: number = -1;
  keiyakuHojinList: KeiyakuHojinListType = []; // search-formに渡すためだけの値
  kyotenList: KyotenListT = []; // search-formに渡すためだけの値
  systemList: SystemListType = [];
  systemRoleList: SystemRoleListType = []; // search-formに渡すためだけの値

  isFinishedPrepare: boolean = false;
  searchResult?: SearchResultType = undefined;
  mailConfigForm: MailConfigInputForm = {};
  headerMessage = new MamezoMessage();

  /** フォームの初期状態 */
  getInitSearchForm() {
    return {
      mailGroup: '',
      keiyakuHojin: '',
      keiyakuHojinCd: '',
      keiyakuHojinNm: '',
      kyoten: '',
      kyotenCd: '',
      kyotenNm: '',
      kyotenName: '',
      systemId: '',
      kashidashisaki: 'INCLUDE',
      jusho: '',
      roleId: '',
      roleNm: '',
      kyotenTantosha: '',
      // isUprUser: false,
      page: new FormBuilder().group({
        pageTo: 1,
        linesPerPage: 200,
      }),
      sort: {
        key: 'CODE',
        subKey: null,
        order: 'ASC',
      },
    };
  }

  constructor(private formBuilder: FormBuilder, private elementRef: ElementRef) {
    logger.log("environment", environment);
    this.searchForm = this.formBuilder.group(this.getInitSearchForm());
    this.topPageService.getRefreshToken(this.init);
  }

  /** 初期処理 */
  init = () => {
    this.getSearchConditions();
    this.isFinishedPrepare = true;
  };

  ngAfterViewInit() {
    this.elementRef.nativeElement.querySelectorAll(".div-tooltip").forEach((el: HTMLElement) => {
      el.addEventListener("touchstart", (e) => {
        e.stopPropagation();
        e.preventDefault();
        const message = el.getAttribute("title");
        const tooltip = document.createElement("div");
        tooltip.classList.add("for-mobile");
        tooltip.textContent = message;
        el.appendChild(tooltip);
      });
      el.addEventListener("touchend", (e) => {
        e.stopPropagation();
        e.preventDefault();
        el.querySelector(".for-mobile")?.remove();
      });
    });
  }

  /** ページプルダウン */
  pageList: Array<{
    index: number;
    selected: boolean;
  }> = [{ index: 1, selected: true }];

  @ViewChild('search_form') searchFormComponent?: SearchFormComponent;

  /** 登録確認ダイアログ関連 */
  registerConfirms = {
    isOpen: false,
    open: () => {
      fixScroll();
      this.registerConfirms.isOpen = true;
    },
    clickLeft: () => {
      this.registerConfirms.isOpen = false;
      releaseScroll();
    },
    clickRight: () => {
      if (this.isSubmitting) return;
      this.isSubmitting = true;
      this.headerMessage.hide();
      this.register((res) => {
        logger.log('登録成功');
        logger.log(res);
        this.search(this.searchForm.value);
        this.registerComplete.isOpen = true;
        this.registerConfirms.isOpen = false;
        this.isSubmitting = false;
      }, (err) => {
        logger.log(err);
        this.isSubmitting = false;
        this.registerConfirms.isOpen = false;
        this.handleError(err);
      });
    }
  }
  /** 登録完了ダイアログ関連 */
  registerComplete = {
    isOpen: false,
    clickRight: () => {
      this.registerComplete.isOpen = false;
      releaseScroll();
    }
  }
  /** 検索前登録確認ダイアログ関連 */
  registerBeforeSearchConfirm = {
    isOpen: false,
    isOpenVisibleColumn: false,
    open: (options?: SearchOptionType) => {
      this.registerBeforeSearchConfirm.isOpen = true;
      fixScroll();
      this.registerBeforeSearchConfirm.isOpenVisibleColumn = options?.openVisibleColumn || this.isOpenVisibleColumn;
    },
    clickLeft: () => {
      // 「キャンセル」
      this.registerBeforeSearchConfirm.isOpen = false;
      releaseScroll();
    },
    clickRight: () => {
      // OK（編集内容は失われます）
      this.mailConfigForm = {};
      this.registerBeforeSearchConfirm.isOpen = false;
      releaseScroll();
      this.search(this.searchForm.value, {openVisibleColumn: this.registerBeforeSearchConfirm.isOpenVisibleColumn});
    }
  };
  /** 検索前登録完了ダイアログ関連 */
  registerBeforeSearchComplete = {
    isOpen: false,
    clickRight: () => {
      this.registerBeforeSearchComplete.isOpen = false;
      releaseScroll();
    }
  }
  /** クリア前登録確認ダイアログ関連 */
  registerBeforeClearConfirm = {
    isOpen: false,
    open: () => {
      this.registerBeforeClearConfirm.isOpen = true;
      fixScroll();
    },
    clickLeft: () => {
      // キャンセル
      this.registerBeforeClearConfirm.isOpen = false;
      releaseScroll();
    },
    clickRight: () => {
      // OK
      this.mailConfigForm = {};
      this.registerBeforeClearConfirm.isOpen = false;
      releaseScroll();
      this.handleClear();
    }
  };
  /** クリア前登録完了ダイアログ関連 */
  registerBeforeClearComplete = {
    isOpen: false,
    clickRight: () => {
      this.registerBeforeClearComplete.isOpen = false;
      releaseScroll();
    }
  }

  isLoading : boolean = false;
  /** 登録処理中 */
  isSubmitting : boolean = false;
  mailGroupChoices: MailGroupChoiceList = new MailGroupChoiceList();
  /** 検索条件取得 */
  getSearchConditions = (): void => {
    logger.log("getSearchConditions")
    this.systemRoleList = [];
    this.topPageService.getSearchConditions().subscribe({
      next: (res) => {
        logger.log('getSearchConditions: ', res.body);
        const data = res.body;
        if (!data) return;
        data?.mailGroups.forEach((mailGroup) => {
          this.mailGroupChoices.add(mailGroup);
          if (!this.systemList.find(system => String(system.cd) === String(mailGroup.mz_msystem_id))) {
            this.systemList.push({cd: String(mailGroup.mz_msystem_id), nm: mailGroup.msystem_name});
          }
        });
        this.mailGroupChoiceList = this.mailGroupChoices.getForOptions();
        this.kyotenCount = data.kyotenCount;
        this.kyotenList = data.kyotenList;
        this.keiyakuHojinList = data.keiyakuHojinList;
        data.roleList.forEach(role => {
          if (!this.systemRoleList.some(r => r.system_nm == role.system_nm)) {
            this.systemRoleList.push({system_nm: role.system_nm, roles: []});
          }
          this.systemRoleList.find(r => r.system_nm == role.system_nm)?.roles.push({role_id: role.role_id, role_nm: role.role_nm});
        });
      },
      error: (err) => {
        logger.log(err);
        this.handleError(err);
      },
    });
  };
  configColCheck: Record<string, { checked: boolean }> = {};

  // 検索結果表示項目の設定
  displayColConfig = JSON.parse(JSON.stringify(defaultDisplayColConfig));
  // 検索結果表示項目の開閉
  isOpenVisibleColumn = false;
  onToggleVisibleColumn(value: boolean) {
    this.isOpenVisibleColumn = value;
  }

  /** 登録処理 */
  register(callbackOnSuccess?: (res: any) => void, callbackOnError?: (err: any) => void) {
    const form: MailConfigRequestForm = [];
    for (let kyoten_cd in this.mailConfigForm) {
      for (let mz_mail_group_id in this.mailConfigForm[kyoten_cd].configs) {
        const config = this.mailConfigForm[kyoten_cd].configs[mz_mail_group_id];
        if (!config.is_updated) continue;
        if (!config.is_configurable) continue;
        form.push({
          kyoten_cd,
          mz_mail_group_id: Number(mz_mail_group_id.replaceAll(/^m/g, '')),
          mz_mail_config_id: config.mz_mail_config_id,
          subscribe: config.subscribe.value || false,
          revision: config.revision,
        });
      }
    }
    const _callbackOnSuccess = callbackOnSuccess || ((res: any) => {
      logger.log(res);
      this.search(this.searchForm.value);
    });
    const _callbackOnError = callbackOnError || ((err: any) => {
      logger.log(err);
      this.handleError(err);
    });
    logger.log(form);
    this.topPageService.registerMailConfig(form).subscribe({
      next: _callbackOnSuccess,
      error: _callbackOnError,
    });
  }
  /** 「表示」ボタンクリック */
  onDefaultButtonClicked() {
    this.searchForm = this.formBuilder.group(this.getInitSearchForm());
    this.searchFormComponent?.closeCondition();
    // this.searchFormComponent?.closeVisibleColumn();
    this.searchFormComponent?.initDisplayColConfig();
    this.searchReady(this.searchForm.value, {openVisibleColumn: true});
  }
  /** 「クリア」ボタンクリック */
  onClearButtonClicked() {
    this.clearReady();
  }
  /** クリアの準備をする（未登録の変更があったら確認する） */
  clearReady() {
    if (this.isAnyUpdated()) {
      this.registerBeforeClearConfirm.open();
      return;
    }
    this.handleClear();
  }
  /** クリア処理 */
  handleClear() {
    this.headerMessage.hide();
    this.searchForm = this.formBuilder.group(this.getInitSearchForm());
    this.mailConfigForm = {};
    this.displayColConfig = JSON.parse(JSON.stringify(defaultDisplayColConfig));
    this.searchResult = undefined;
  }
  /** 「検索」ボタンクリック */
  onSearchButtonClicked() {
    this.searchForm.controls.page.setValue({
      pageTo: 1,
      linesPerPage: this.searchForm.controls.page.value.linesPerPage,
    });
    this.searchReady(this.searchForm.value, {openVisibleColumn: true});
  }
  /** 検索結果コンポーネントからの再検索 */
  doSearchFromResult(searchFormValue: any) {
    this.searchForm.setValue(searchFormValue);
    this.searchReady(searchFormValue)
  }
  isAnyUpdated(): boolean {
    return Object.values(this.mailConfigForm).some(mcf => mcf.is_updated);
  }
  mailGroupDetail: string = '';
  isMailGroupDetailModal: boolean = false;
  onclickMailGroupDetailModal(message: string, $event: Event) {
    this.mailGroupDetail = message;
    this.isMailGroupDetailModal = true;
    fixScroll();
    $event.stopPropagation();
    $event.preventDefault();
  }
  closeMailGroupDetailModal() {
    releaseScroll();
    this.isMailGroupDetailModal = false;
  }
  /** 検索の準備をする（未登録の変更があったら確認する） */
  searchReady(form: SearchForm, options?: SearchOptionType) {
    if (this.isAnyUpdated()) {
      this.registerBeforeSearchConfirm.open(options);
      return;
    }
    this.search(form, options);
  }
  /** 検索処理 */
  search(form: SearchForm, options?: SearchOptionType) {
    this.headerMessage.hide();
    this.isLoading = true;
    this.searchResult = undefined;
    this.mailConfigForm = {};
    // this.roleList = [];
    this.mailGroupChoiceList = [] // 空配列で初期化しないと検索ボタンクリック時にメール受信区分が空になってしまう
    this.getSearchConditions(); // 設定可能な拠点数、検索条件の拠点などのリフレッシュ（検索処理と並行して実行
    this.topPageService.search(form).subscribe({
      next: (res) => {
        this.searchResult = res || {};
        if (!this.searchResult) return;
        if (!this.searchResult.kyotens) return;

        // ロールがカンマ区切りで来るので改行に変換（backend で配列にしてもいいのですが）
        this.searchResult.kyotens.forEach((kyoten) => {
          for (let idx in kyoten.system_roles) {
            let roleStr = kyoten.system_roles[idx];
            kyoten.system_roles[idx] = roleStr?.replace(', ', '\n');
          }
        });

        // 検索結果表示項目の表示
        if (options?.openVisibleColumn) {
          this.isOpenVisibleColumn = true;
        }

        // 検索結果欄のスクロールを上に戻す
        searchResultDivScrollTop();

        logger.log(this.searchResult);
        // userMailGroupIds の初期化
        let userMailGroupIds: number[] = [];
        this.searchResult.msystem_mail_groups
          .filter((msystemMailGroup) =>
            this.systemList.find(
              (system) => String(system.cd) == String(msystemMailGroup.mz_msystem_id)
            )
          )
          .forEach((msystemMailGroup) => {
            userMailGroupIds = userMailGroupIds.concat(
              msystemMailGroup.mail_groups.map((mailGroup) => mailGroup.id)
            );
          });
        logger.log({ userMailGroupIds });

        // configColCheck の初期化
        this.searchResult.msystem_mail_groups.forEach((msystemMailGroup) => {
          msystemMailGroup.mail_groups.forEach(
            (mg) => (this.configColCheck['m' + mg.id] = { checked: false })
          );
        });

        // メール受信設定フォームの初期化
        for (let kyoten of this.searchResult.kyotens) {
          this.mailConfigForm[kyoten.kyoten_cd] = {
            configs: {},
            is_updated: false,
          };
          for (let mz_mail_group_id in this.searchResult.mail_configs[kyoten.kyoten_cd].configs) {
            const config = this.searchResult.mail_configs[kyoten.kyoten_cd].configs[mz_mail_group_id];
            // if (!userMailGroupIds.includes(Number(mz_mail_group_id.replaceAll(/^m/g, ''))))
            //   continue;
            this.mailConfigForm[kyoten.kyoten_cd].configs[mz_mail_group_id] = {
              mz_mail_config_id: config.mz_mail_config_id,
              subscribe: new FormControl(
                config.subscribe
              ),
              original: config.subscribe,
              revision: config.revision,
              is_updated: false,
              is_configurable: config.is_configurable,
            };
          }
        }
        logger.log('mailConfigForm', this.mailConfigForm);

        // ページ
        const page = this.searchForm.controls.page.value;
        this.pageList = [];
        if (this.searchResult.kyotens.length > 0) {
          const totalRows = this.searchResult.kyotens[0].result_count;
          for (let i = 1; i <= totalRows / page.linesPerPage + 1; i++) {
            this.pageList.push({
              index: i,
              selected: i == page.pageTo,
            });
          }
        }
        scrollResultToTop();
      },
      error: (err) => {
        logger.log(err);
        this.isLoading = false;
        this.handleError(err);
      },
      complete: () => {
        this.isLoading = false;
      }
    });
  }
  /** 検索結果欄の表示条件 */
  isSearchResult() {
    return this.isFinishedPrepare && this.searchResult && this.searchResult.kyotens.length > 0;
  }
  handleError(err: any) {
    logger.log("handleError", err);
    switch (err.status) {
      // ファイルを用意した分だけここに書く
      case 401:
      case 500:
      case 503:
        this.router.navigate(['/' + err.status]);
        break;
      default:
        // 404など、画面ヘッダに表示するもの
        this.headerMessage.setMessage(err.error).show();
        releaseScroll();
        scrollToTop();
    }
  }
}
